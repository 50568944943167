<template>
  <v-card>
    <v-card-title
      class="error--text text--darken-1 headline error--text text-uppercase font-weight-bold text--darken-1"
    >
      <v-icon class="mr-2 mt-n1" color="error"
        >mdi-account-group</v-icon
      >
      Roles
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-card-title>
    <p class="grey--text ml-8 mt-n5">List of all user roles</p>
    <v-divider></v-divider>

    <v-row class="pa-4" justify="space-between">
      <v-col cols="5">
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 text-capitalize"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus-thick</v-icon> New
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="error--text text--darken-1">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 500px">
              <v-col cols="12" sm="12" md="12">
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Role Name"
                    :rules="nameRules"
                    outlined
                    dense
                    color="grey"
                  ></v-text-field>
                  <v-text-field
                    v-model="editedItem.slug"
                    label="Code"
                    :rules="nameRules"
                    outlined
                    dense
                    color="grey"
                  ></v-text-field>
                  <v-textarea
                    v-model="editedItem.description"
                    label="Description"
                    outlined
                    dense
                    color="grey"
                  ></v-textarea>
                  <h4>Select abilities</h4>
                  <v-divider></v-divider>
                  <v-treeview
                    selectable
                    selected-color="primary"
                    :items="abilities"
                    item-text="title"
                    item-key="title"
                    :value="ability_ids"
                    v-model="editedItem.ability_ids"
                    open-all
                  >
                  </v-treeview>
                  <v-divider></v-divider>
                  <template v-if="!editedItem.ability_ids">
                    No roles selected.
                  </template>
                  <template v-else>
                    <v-row align="center" justify="center">
                      <div v-for="node in editedItem.ability_ids" :key="node">
                        <v-chip
                          class="ma-1"
                          justify="center"
                          color="green"
                          outlined
                          dense
                          small
                        >
                          {{ node }}
                        </v-chip>
                      </div>
                    </v-row>
                  </template>
                </v-form>
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="error"
                class="text-capitalize ml-auto"
                dark
                @click="close"
              >
                Cancel <v-icon small>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="save"
              >
                Submit <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider></v-divider>
        <div v-if="this.roles.length">
          <v-treeview
            :active.sync="active"
            :items="items"
            :open.sync="open"
            activatable
            color="primary"
            open-on-click
            transition
            open-all
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children"> mdi-account </v-icon>
            </template>
            <template v-slot:append="{ item }">
              <v-btn
                color="grey"
                class="mx-1 my-3"
                fab
                x-small
                dark
                @click="editItem(item)"
                v-if="!item.children"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                color="error"
                class="mx-1 my-3"
                fab
                x-small
                dark
                @click="deleteItem(item)"
                v-if="!item.children"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-treeview>
        </div>
        <div v-else>
          <p caption class="mt-3">No role created, please create new...</p>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            Select a Role
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <h3 class="headline mb-2">Name : {{ selected.name }}</h3>
              <div class="primary--text mb-2">Code: {{ selected.slug }}</div>
              <div class="primary--text mb-2">
                Description: {{ selected.description }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-row class="text-left" tag="v-card-text">
              <v-treeview
                selectable
                selected-color="primary"
                item-disabled="locked"
                :items="abilities"
                item-text="title"
                item-key="title"
                :value="ability_ids"
              >
              </v-treeview>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import UserService from "../../services/user.service";
import moment from "moment";

export default {
  data: () => ({
    active: [],
    active_role: [],
    abilities: [],
    avatar: null,
    loading: false,
    open: [],
    roles: [],
    selectionType: "leaf",
    selection: [],
    // users end
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    dialog: false,
    dialog1: false,
    headers: [
      {
        text: "Name",
        align: "start",
        filterable: true,
        value: "title",
      },
      {
        text: "Code",
        value: "slug",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Date created",
        value: "created_at",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    items() {
      return [
        {
          name: "User Roles",
          children: this.roles,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.roles.find((role) => role.id === id);
    },
    ability_ids: {
      get() {
        if (!this.active.length) return undefined;
        const id = this.active[0];
        return this.roles.find((role) => role.id === id).ability_ids;
      },
      set() {},
    },
    checked(id, array) {
      if (!array.length) return false;
      return array.find((item) => item.id === id);
    },
    selected_role() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      let role = this.roles.find((role) => role.id === id);
      return role.abilities;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllRoles();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    getAllRoles() {
      return UserService.getAllRoles().then(
        (response) => {
          if (response.status == 200) {
            this.roles = response.data.data.roles;
            this.abilities = response.data.data.abilities;
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.roles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.roles.indexOf(item);
      var confirmDelete = confirm("Are you sure you want to delete this item?");
      if (confirmDelete && this.roles.splice(index, 1)) {
        UserService.deleteRole(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        if (this.editedIndex > -1) {
          Object.assign(this.roles[this.editedIndex], this.editedItem);
          this.editedItem.company_id = this.user.company.id;
          this.editedItem.title = this.editedItem.name;

          UserService.updateRole(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("auth/updateUser", this.user);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          this.editedItem.company_id = this.user.company.id;
          this.editedItem.title = this.editedItem.name;
          UserService.storeRole(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.getAllRoles();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
